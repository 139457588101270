<template>
  <div class="container">
    <h2 class="fs-5 py-3 mb-0">UI Components</h2>
    <hr class="mt-0 pt-0" />
    <div class="py-2">
      <AppLogo />
    </div>

    <div class="py-2">
      <ButtonCircle icon="settings" :action="ping" uid="btnCirc"></ButtonCircle>
    </div>

    <div class="py-2">
      <ButtonMain class="me-2" :action="ping" uid="btnMain1">
        Main Button
      </ButtonMain>

      <ButtonMain class="me-2 rounded-pill" :action="ping">
        No Icon
      </ButtonMain>

      <ButtonMain class="me-2 rounded-pill" icon="save" :action="ping">
        My Lef Button
      </ButtonMain>

      <ButtonMain
        class="rounded-pill me-2"
        icon="edit"
        iconPosition="right"
        :action="ping"
      >
        My Right Button
      </ButtonMain>

      <ButtonMain class="rounded-pill" :action="ping" :loader="true">
        Load that
      </ButtonMain>
    </div>


    <div class="py-2">
      <OptionsDropdown
        :action="ping"
        :onOpen="onOpen"
        :onClose="onClose"
        :collection="basicCollection"
      ></OptionsDropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ddCollection: [
        {
          title: "Option 1",
          name: "option-1",
          type: "item",
          disabled: false,
        },
        {
          title: "Option 2",
          name: "option-2",
          type: "item",
          disabled: true,
        },
        {
          type: "divider",
        },
        {
          type: "header",
          title: "MORE",
        },
        {
          title: "Option 3",
          name: "option-3",
          type: "item",
          disabled: false,
        },
      ],
    };
  },

  computed: {
    basicCollection() {
      return this.$root.$ui.helper.dropdowns.COMPLETE;
    },
  },

  methods: {
    ping(data) {
      console.log('UI.ping', data);
    },

	onOpen(data) {
      console.log('UI.onOpen', data);
	},

	onClose(data) {
      console.log('UI.onClose', data);
	}
  },
};
</script>
